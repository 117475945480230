<template>
  <div class="account_settings">
    <div class="flex alc jcsb mb20">
      <div class="public_title flex1">个人信息</div>

      <el-button @click="save" type="primary">保存</el-button>
    </div>

    <div v-loading="loading">
      <div class="item">
        <div class="label">头像</div>
        <div class="value">
          <el-upload
            class="avatar-uploader"
            action="https://zizhihelp.com/webapi/file/upload2"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
            :before-upload="beforeUpload"
            accept=".jpg,.jpeg,.png"
            :headers="headers"
          >
            <el-avatar :size="60" :src="userInfo.headimgurl">
              <img
                src="https://www.zizhihelp.com/upload/static/web/mine_touxiang.png"
              />
            </el-avatar>
          </el-upload>
        </div>
      </div>

      <div class="item">
        <div class="label">昵称</div>
        <div class="value">
          <el-input
            v-model="userInfo.nickname"
            placeholder="请输入昵称"
          ></el-input>
        </div>
      </div>

      <div class="item">
        <div class="label">账号ID</div>
        <div class="value">
          <div>
            {{ userInfo.id }}
          </div>

          <img
            @click="copyText(userInfo.id)"
            title="复制"
            class="icon_copy"
            src="https://www.zizhihelp.com/upload/static/web/mine_fuzhi.png"
            alt=""
          />
        </div>
      </div>

      <div class="item">
        <div class="label">手机号</div>
        <div class="value">
          {{ userInfo.phone }}
          <el-button size="mini" @click="changePhone"> 更换手机号 </el-button>
        </div>
      </div>

      <div class="item">
        <div class="label">姓名</div>
        <div class="value">
          <el-input v-model="userInfo.name" placeholder="请输入姓名"></el-input>
        </div>
      </div>

      <div class="item">
        <div class="label">公司名称</div>
        <div class="value">
          <el-input
            v-model="userInfo.company"
            placeholder="请输入姓名"
          ></el-input>
        </div>
      </div>

      <div class="item">
        <div class="label">账号注销</div>
        <div class="value cursorP" @click="cancelAccount">
          提交申请
          <img
            class="icon_arrow"
            src="https://www.zizhihelp.com/upload/static/web/mine_jiantou.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- 更换手机号 -->
    <customDialog v-model="changePhoneShow" title="更换手机号" width="500px">
      <el-form
        :model="changePhoneForm"
        :rules="rules"
        ref="changePhoneForm"
        label-width="64px"
      >
        <el-form-item prop="phone" label="手机号">
          <el-input
            placeholder="请输入更换的手机号"
            v-model.trim="changePhoneForm.phone"
            clearable
          >
          </el-input>
        </el-form-item>

        <el-form-item prop="code" label="验证码">
          <div class="code_input">
            <el-input
              @keydown.native.enter="verify"
              v-model.trim="changePhoneForm.code"
              placeholder="请输入验证码"
            >
            </el-input>

            <div class="getCode_btn" @click="getCode">
              {{ isSendCode ? `${countdown}s后重新发送` : "获取验证码" }}
            </div>
          </div>
        </el-form-item>
      </el-form>

      <div class="tar">
        <el-button @click="changePhoneShow = false">取 消</el-button>
        <el-button type="primary" @click="verify"> 确 定 </el-button>
      </div>
    </customDialog>

    <!-- 注销申请提示框 -->
    <customDialog
      v-model="cancelAccountShow"
      title="账号注销申请"
      width="500px"
    >
      <div class="cancel_account">
        <div class="flex alc">
          <img
            class="icon_danger"
            src="https://www.zizhihelp.com/upload/static/web/mine_danger.png"
            alt=""
          />
          申请通过后将删除所有数据，<span class="danger">永久注销账号</span
          >，请谨慎操作！
        </div>

        <div class="mt20 tac mb20">是否确认申请？</div>
      </div>

      <div class="tar">
        <el-button @click="cancelAccountShow = false">取 消</el-button>
        <el-button type="primary" @click="cancelAccountReasonShow = true">
          确 定
        </el-button>
      </div>
    </customDialog>

    <!-- 填写申请原因 -->
    <customDialog
      v-model="cancelAccountReasonShow"
      title="申请原因"
      width="600px"
    >
      <el-form
        ref="cancelForm"
        :model="cancelForm"
        :rules="cancelRules"
        label-width="78px"
      >
        <el-form-item label="申请原因" prop="reason">
          <el-input
            v-model="cancelForm.reason"
            placeholder="请输入申请原因"
            type="textarea"
            :rows="4"
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="tar">
        <el-button
          @click="
            cancelAccountReasonShow = false;
            cancelAccountShow = false;
          "
        >
          取 消
        </el-button>
        <el-button type="primary" @click="submitCancel"> 确 定 </el-button>
      </div>
    </customDialog>
  </div>
</template>

<script>
/* 个人中心 - 账号设置 */
import {
  getUserInfo,
  saveHeaderImage,
  saveWxUser,
  authPhone,
  logout,
} from "@/api/personalCenter";
import { getCodeApi } from "../../api/login";

import local from "@/utils/local.js";

export default {
  data() {
    return {
      userInfo: {},
      changePhoneShow: false,
      changePhoneForm: { phone: "", code: "", checkFlag: false },
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      isSendCode: false,
      countdown: 60,
      cancelAccountShow: false,
      cancelForm: { reason: "" },
      cancelRules: {
        reason: [
          { required: true, message: "请输入申请原因", trigger: "blur" },
        ],
      },
      cancelAccountReasonShow: false,
      loading: false,
      // 图片上传的配置信息,如token等配置信息
      headers: {
        token: localStorage.getItem("token") || "",
      },
    };
  },

  methods: {
    /* 获取数据 */
    // 获取用户信息
    async getData() {
      this.loading = true;
      let { data } = await getUserInfo({ id: this.userInfo.id });
      if (data.id) {
        this.userInfo = data;
        local.set("userInfo", this.userInfo);
        this.$bus.$emit("userInfoChange", this.userInfo);
        this.loading = false;
      }
    },

    /* 操作 */
    // 保存信息
    async save() {
      const { code } = await saveWxUser(this.userInfo);
      if (code === 0) {
        this.$message.success("保存成功！");
        local.set("userInfo", this.userInfo);
        this.$bus.$emit("userInfoChange", this.userInfo);
      }
    },
    // 头像上传
    async handleUploadSuccess(res, file) {
      if (res.url) {
        this.userInfo.headimgurl = res.url;
        const saveRes = await saveHeaderImage({
          id: this.userInfo.id,
          avatarUrl: res.url,
        });
        if (saveRes.data.id) {
          this.userInfo = saveRes.data;
          local.set("userInfo", this.userInfo);
          this.$bus.$emit("userInfoChange", this.userInfo);
        }
        this.$message.success("上传成功！");
      } else {
        this.$message.error("上传失败，请重试");
      }
    },
    // 文件上传前
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 复制文本
    async copyText(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.$message.success("复制成功");
      } catch (err) {
        this.$message.error("复制失败,请重试！");
        console.error("复制到剪贴板失败:", err);
      }
    },
    // 账号注销申请
    cancelAccount() {
      const flag = this.userInfo.logoutStatus == "待审核" ? true : false;
      if (flag) {
        this.$message.info("账号注销申请已提交，请耐心等待审核结果！");
      } else {
        this.cancelAccountShow = true;
      }
    },
    // 提交账号注销申请
    submitCancel() {
      this.$refs.cancelForm.validate(async (valid) => {
        if (valid) {
          let res = await logout(this.cancelForm);
          if (res.code == 0) {
            this.$message.success("申请成功，请等待审核！");
            this.userInfo = res.data;
            local.set("userInfo", res.data);
            this.$bus.$emit("userInfoChange", res.data);
            this.cancelAccountReasonShow = false;
            this.cancelAccountShow = false;
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    // 更换手机号
    changePhone() {
      this.changePhoneForm = { phone: "", code: "", checkFlag: false };
      this.changePhoneShow = true;
    },
    // 获取验证码
    async getCode() {
      let valid = false;
      this.$refs.changePhoneForm.validateField("phone", (errMsg) => {
        if (!errMsg) {
          valid = true;
        } else {
          valid = false;
        }
      });
      if (!valid || this.isSendCode) return;

      const { code } = await getCodeApi({
        phone: this.changePhoneForm.phone,
      });
      if (code == 0) {
        this.$message.success("发送成功！");
        this.isSendCode = true;
        setInterval(() => {
          this.countdown--;
          if (this.countdown < 0) {
            this.isSendCode = false;
            this.countdown = 60;
          }
        }, 1000);
      }
    },
    // 验证手机号和验证码是否正确
    async verify() {
      const res = await authPhone(this.changePhoneForm);
      if (res.code === 0) {
        this.$message.success("更换成功！");
        this.userInfo = res.data;
        local.set("userInfo", res.data);
        this.$bus.$emit("userInfoChange", this.userInfo);
        this.changePhoneShow = false;
        this.changePhoneForm = { phone: "", code: "", checkFlag: false };
      } else if (res.code === -2) {
        this.combinedAccount();
      } else {
        this.$message.error(res.msg);
      }
    },
    // 合并账号
    combinedAccount() {
      console.log("合并账号");

      // this.$confirm(
      //   "该手机号已绑定其他账号，是否合并账号？点击确定继续",
      //   "提示",
      //   {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   }
      // ).then(async () => {
      //   const loading = this.$loading({
      //     lock: true,
      //     text: "合并中...",
      //     spinner: "el-icon-loading",
      //     background: "rgba(0, 0, 0, 0.7)",
      //   });
      //   authPhone({ ...this.changePhoneForm, checkFlag: true }).then((res) => {
      //     loading.close();

      //     if (res.code !== 0) {
      //       this.$message.error(res.msg);
      //     } else {
      //       this.$message.success("更换成功！");
      //       this.userInfo = res.data;
      //       local.set("userInfo", res.data);
      //       this.$bus.$emit("userInfoChange", this.userInfo);
      //       this.changePhoneShow = false;
      //       this.changePhoneForm = { phone: "", code: "", checkFlag: false };

      //       that.$store.commit("SET_SOCKET", null);
      //       const timer = setTimeout(() => {
      //         const platform = uni.getSystemInfoSync().uniPlatform;
      //         that.$store.dispatch(
      //           "connectWebSocket",
      //           `wss://www.zizhihelp.com/api/websocket/${res.data.id}/${platform}`
      //         );
      //         clearTimeout(timer);
      //       }, 1000);
      //     }
      //   });
      // });
    },

    /* 初始化 */
    __init__() {
      this.userInfo = local.get("userInfo");
      this.getData();
    },
  },

  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.account_settings {
  // width: 60%;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  background-color: #f9fafc;
  border-radius: 6px;
  margin-bottom: 12px;
  font-size: 16px;

  .label {
    color: #666666;
  }

  .value {
    color: #333333;
    display: flex;
    align-items: center;

    .icon_copy {
      width: 16px;
      height: 16px;
      margin-left: 10px;
      cursor: pointer;
    }

    .icon_arrow {
      height: 12px;
      margin-left: 10px;
    }
  }

  /deep/.el-input__inner {
    background-color: transparent;
    border: none;
    text-align: right;
    padding: 0px;
    height: 20px;
    font-size: 16px;
    color: #333;
  }
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

/deep/.el-button--mini,
.el-button--mini.is-round {
  padding: 7px 10px;
  margin-left: 10px;
}

/deep/.el-button--primary {
  background-color: #4f7bff;
  border-color: #4f7bff;
}

.code_input {
  position: relative;

  .getCode_btn {
    color: #1472ff;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  /deep/.el-input__inner {
    padding-right: 90px;
  }
}

.cancel_account {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #333;

  .icon_danger {
    width: 30px;
    margin-right: 5px;
  }

  .danger {
    font-weight: bold;
    color: red;
  }
}
</style>
